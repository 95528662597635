import wave from "../../images/geral/wind.svg"
import ListOfInfo from "./educationOrExperienceList"
import Tool from "./favoriteTools"

import bash from '../../images/tools/Bash.png'
import css from '../../images/tools/CSS3.png'
import django from '../../images/tools/Django.png'
import docker from '../../images/tools/Docker.png'
import fastapi from '../../images/tools/FastAPI.png'
import git from '../../images/tools/Git.png'
import html from '../../images/tools/HTML5.png'
import js from '../../images/tools/JavaScript.png'
import mongoDb from "../../images/tools/MongoDB.png"
import postgresDb from "../../images/tools/PostgresSQL.png"
import poetry from '../../images/tools/Python Poetry.png'
import python from '../../images/tools/Python.png'
import react from '../../images/tools/React.png'
import sass from '../../images/tools/Sass.png'


const ResumeComponent = () => {

    const myEducation = [
        {
            "date" : "2023 - 2025",
            "course" : "Web development and comunication",
            "institute" : "Etic Algarve",
            "description": "An intensive course in web development and communication, teaching essential principles of creating impactful online experiences."
        }
    ]

    return (
        <div className="resume" id="resume">
            <div className="resume_subtitle" data-aos="fade-left" >
                <img src={wave} alt="icon"/>
                <p>
                    Resume
                </p>
            </div>
            <div className="resume_title" data-aos="fade-left">
                <h2>
                    Education and practical experience
                </h2>
            </div>
            <div className="resume_about" data-aos="fade-left">
                <p>
                    At 20 years old, I'm a dedicated full-stack development enthusiast with over 2 years of hands-on learning experience. Currently studying at ETIC Algarve to further expand my skills, I'm eager to apply my knowledge in building robust and scalable web applications. With a solid foundation in both frontend and backend technologies, I'm committed to delivering seamless user experiences and well-architected solutions as I prepare to enter the professional field.
                </p>
            </div>

            <div className="resume_education">
                <ListOfInfo infoList = {myEducation} title={"My education"}/>
            </div>

            <div className="resume_tools">
                <div className="resume_tools_title" data-aos="fade-left">
                    <h2>
                        My favorite tools
                    </h2>
                </div>
                
                <div className="resume_tools_tools">
                    <Tool name={"Html"} image={html}/>
                    <Tool name={"Css"} image={css}/>
                    <Tool name={"Sass"} image={sass} />
                    <Tool name={"JavaScript"} image={js}/>
                    <Tool name={"React"} image={react}/>
                    <Tool name={"Python"} image={python}/>
                    <Tool name={"Poetry"} image={poetry}/>
                    <Tool name={"Django"} image={django}/>
                    <Tool name={"Fastapi"} image={fastapi} />
                    <Tool name={"Docker"} image={docker}/>
                    <Tool name={"Bash"} image={bash} />
                    <Tool name={"Mongo"} image={mongoDb} />
                    <Tool name={"Postgres"} image={postgresDb} />
                    <Tool name={"Git"} image={git} />
                </div>
            </div>
        </div>
    )
}

export default ResumeComponent