const IndexLogo = () => {
    return (
            <svg fill="#5e43f3"  viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">

            <g id="ai">
                <path d="M13.36,21.78l-5.15,24.46c-.2,.94,.08,1.92,.73,2.62l9.99,10.66c.28,.3,.67,.47,1.08,.49h.05c.39,0,.77-.15,1.05-.42,.22-.21,.38-.47,.45-.77l2.17-9.09,6.9,3.61c.85,.44,1.86,.44,2.72,0l6.9-3.61,2.17,9.09c.2,.83,1.03,1.34,1.86,1.14,.29-.07,.56-.22,.77-.44l9.99-10.66c.66-.7,.93-1.68,.73-2.62l-5.15-24.46c-.16-.75-.42-1.47-.79-2.15l-6.46-11.92,1.71-1.31c.59-.45,.7-1.29,.25-1.88-.25-.33-.65-.52-1.06-.52H19.72c-.74,0-1.34,.6-1.34,1.34,0,.42,.2,.81,.53,1.07l1.71,1.31-6.46,11.92c-.37,.67-.63,1.4-.79,2.15ZM20.12,4.82h0Zm-4.9,17.87l10.62,7.49-15.21,14.34,4.6-21.82Zm4.6,34.83l-9.41-10.04h0l15.26-14.39-5.84,24.43Zm12.62-5.97c-.27,.14-.59,.14-.86,0l-7.35-3.84,3.93-16.43c.24-1-.15-2.02-.99-2.61l-1.52-1.07,6.36-4.93,6.36,4.93-1.52,1.07c-.84,.59-1.23,1.62-.99,2.61l3.93,16.43-7.35,3.84Zm-4.73-34.74c-.06-.05-.08-.13-.04-.2l1.4-2.43,1.52,1.17c.83,.63,1.99,.64,2.82,0l1.52-1.17,1.4,2.43c.04,.07,.02,.15-.04,.2l-4.29,3.32-4.29-3.32Zm16.48,40.71l-5.84-24.43,15.26,14.38h0l-9.41,10.05Zm9.2-13.01l-15.21-14.34,10.62-7.49,4.6,21.82Zm-5.25-23.81l-8.06,5.68-6.44-4.98,3.88-3.01c.85-.66,1.09-1.85,.55-2.78l-1.53-2.65,5.24-4.01c6.31,11.64,5.81,10.69,6.35,11.76Zm-5.79-14.71l-.85,.65h0l-9.3,7.12c-.12,.09-.28,.09-.39,0l-9.3-7.12h0l-.85-.65h20.69Zm-20.12,2.95l5.24,4.01-1.53,2.65c-.54,.93-.3,2.13,.55,2.78l3.88,3.01-6.44,4.98-8.06-5.68c.56-1.09,.06-.14,6.35-11.76Z" />
            </g>

        </svg>

    )
}

export default IndexLogo