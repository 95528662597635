import wave from "../../images/geral/wind.svg"
import send from "../../images/geral/send.png"

import {  useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import emailjs from '@emailjs/browser';

import { useCallback } from "react";
import toast, { Toaster } from "react-hot-toast";

const ContactComponent = (props) => {    
    const { executeRecaptcha } = useGoogleReCaptcha()

    const apiKey = process.env.REACT_APP_API_KEY
    
    emailjs.init({
        publicKey: apiKey,
    })
    
    
    const handleReCaptchaVerify = useCallback(async (event) => {
        event.preventDefault();
        
        const serviceKey = process.env.REACT_APP_SERVICE_KEY
        const templateKey = process.env.REACT_APP_TEMPLATE_KEY
        if (!executeRecaptcha) {
            console.log('executeRecaptcha not yet available');
            return;
        }
    
        const formData = new FormData(event.target);
        const name = formData.get('from_name');
        const company = formData.get('from_company');
        const email = formData.get('from_email');
        const phone = formData.get('from_phone');
        const message = formData.get('message');
    
        try {
            // Executa o reCAPTCHA e obtém o token
            const token = await executeRecaptcha('submit');

            const email_info = {
                from_name: `${name}`,
                from_company: `${company}`,
                from_email: `${email}`,
                from_phone: `${phone}`,
                message: `${message}`,
                "g-recaptcha-response": token, // Adiciona o token ao envio

            };

            console.log(email_info);
            

            emailjs.send(
                    serviceKey,
                    templateKey, 
                    email_info
            
            );
            // Processa os dados aqui (e.g., envie o email usando emailjs)
        } catch (error) {
            toast.error("Error on recaptcha, pls try again later.");
        } finally {
            toast.success("Message sended.");
        }
    }, [executeRecaptcha]);

    return (
        <div className="contact" id="contact">
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            <div className="contact_subtitle" data-aos="fade-left">
                <img src={wave} alt="wind" />
                <p>
                    Contact
                </p>
            </div>

            <div className="contact_title" data-aos="fade-left">
                <h2>
                    Lets build something amazing together !
                </h2>
            </div>

            <form onSubmit={handleReCaptchaVerify}>
                    <div className="contact_form_info">
                        <div className="contact_form_info_names">
                            {/* user name */}
                            <input type="text" placeholder="Your name *" required data-aos="fade-left" name="from_name" />
                            {/* company name */}
                        <   input type="text" placeholder="Company name *" data-aos="fade-left" name="from_company"  />
                        </div>
                        <div className="contact_form_info_contacts">
                            {/* email contact */}
                            <input type="text" placeholder="Email address *" required data-aos="fade-left" name="from_email" />
                            {/* number contact */}
                        <input type="text" placeholder="Phone Number *" required data-aos="fade-left" name="from_phone"  />
                        </div>
                    </div>
                    
                    <div className="contact_form_message">
                        <textarea name="message" id="text" placeholder="Your message *" data-aos="fade-left" required  /> 
                    </div>

                    <button type="submit"> Send Message <img src={send} alt=""/></button>
                </form>
        </div>
    )
}

export default ContactComponent
