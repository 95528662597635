import wave from "../../images/geral/wind.svg"
import ProjectCard from "./projectCard"
import mcFiles from "../../images/projectsLogos/logo_mc_files.png"
import BNW from "../../images/projectsLogos/BNW.png"
import artGallery from "../../images/projectsLogos/ArtGallery.png"
import todos from "../../images/projectsLogos/todos.png"
import Netics from "../../images/projectsLogos/Netics.png"
import github from "../../images/socialMedias/github.png"

const ProjectsComponent = () => {
    const projects = [
        {
            id: 1,
            title: "McFiles",
            description: "A Google Drive-inspired clone designed to manage files efficiently. MC'Files simulates essential cloud storage functionalities, such as uploading, downloading, and organizing files.",
            logo: mcFiles,
            techs: ["HTML5", "Css3", "JavaScript", "Django", "Poetry", "Docker"],
            link: "https://github.com/mccartheney/file-manager-in-django",
            githubLogo: github
        },
        {
            id: 2,
            title: "BNW",
            description: "A minimalist blog with a monochromatic (black and white) theme where users can create posts with or without images. It provides a clean space for publishing and reading content.",
            logo: BNW,
            techs: ["HTML5", "Sass", "JavaScript", "Django", "Poetry", "Docker"],
            link: "https://github.com/mccartheney/BNW-django-blog/",
            githubLogo: github
        },
        {
            id: 3,
            title: "Art Gallery",
            description: "An app showcasing a gallery of artworks, with detailed information about each piece and its artist. The focus is on creating an elegant and inspiring visual experience for art enthusiasts.",
            logo: artGallery,
            techs: ["HTML5", "Sass","JavaScript"],
            link: "https://github.com/mccartheney/Art-Gallery",
            githubLogo: github
        }, {
            id: 4,
            title: "Netics",
            description: "Netics is an app that quickly and easily connects people. With real-time chat, users can find and interact with others who share similar interests, creating authentic and instant connections.",
            logo: Netics,
            techs: ["HTML5", "Css3", "JavaScript", "Django", "Poetry", "Docker", 'WebSockets'],
            link: "https://github.com/maurograndaoramos/NETIC",
            githubLogo: github
        },
    ];

    return (
        <div className="projects" id="portfolio">
            <div className="projects_subtitle" data-aos="fade-left" id="portfolio">
                <img src={wave} alt="wave" />
                <p>
                    Projects
                </p>
            </div>

            <div className="projects_title" data-aos="fade-left">
                <h2>
                    Check some of my featured projects 
                </h2>
            </div>

            <div className="projects_projects">
            
                {projects.map((project) => (
                    <ProjectCard
                        key={project.id}
                        title={project.title}
                        description={project.description}
                        logo={project.logo}
                        techs={project.techs}
                        link={project.link}
                        githubLogo={project.githubLogo}
                    />
                ))}

            </div>

            <div className="all_projects">
                <a href="https://github.com/mccartheney?tab=repositories" target="_blank">
                    <button>
                        Check all my projects on  my <img srcSet={github}/>
                    </button>
                </a>
            </div>

        </div>
    )
}

export default ProjectsComponent